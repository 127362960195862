const USERNAME = "username";

export const getUsername = () => {
  return window.localStorage.getItem(USERNAME);
};

export const saveUsername = (username) => {
  window.localStorage.setItem(USERNAME, username);
};

export default { getUsername, saveUsername };
