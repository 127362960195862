import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import UsernameService from "@/core/services/username.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("/auth/login/", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, {
            token: data.key,
            username: credentials.username,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [LOGOUT](context) {
    if (JwtService.getToken()) {
      ApiService.post("/auth/logout/", { token: JwtService.getToken() }).catch(
        ({ response }) => {
          context.commit(SET_ERROR, response.data);
        }
      );
    }
    context.commit(PURGE_AUTH);
  },
  [VERIFY_AUTH](context) {
    //context
    if (!JwtService.getToken()) {
      // console.log(context);
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    return ApiService.post("/auth/password/change/", payload)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return error.response.data;
      });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = null;
    JwtService.saveToken(state.user.token);
    if (state.user.username) {
      UsernameService.saveUsername(state.user.username);
    }
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = null;
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
