// USA
export const locale = {
  LANG: "en-US",
  TRANSLATOR: {
    SELECT: "Select your language",
  },
  MENU: {
    DASHBOARD_CONSUMPTION: "Electric energy",
    DASHBOARD_PRODUCTION: "Solar energy",
    DASHBOARD_GAS: "Gas",
    ADMINISTRATION: "Administration",
    USERS: "User Management",
    OVERVIEW: "Overview",
    ENERGY_MANAGEMENT: "Energy Management",
    CLIENT_MANAGEMENT: "Client Management",
    CLIENTS: "Clients",
    ONBOARD: "Onboard Client",
    ENERGY_CONSUMPTION: "Electric energy",
    GAS_CONSUMPTION: "Gas",
    ENERGY_GENERATION: "Solar energy",
    MANAGE_EVENTS: "Manage Events",
    IMPORT_ENERGY: "Import Electric Data",
    IMPORT_GAS: "Import Gas Data",
    PERFORMANCE_ENERGY: "Electric Performance",
    PERFORMANCE_SOLAR: "Solar Performance",
    PERFORMANCE_GAS: "Gas Performance",
    PROFILE: "My Profile",
    PERFORMANCE: "Performance",
    USER_MANAGEMENT: "User Management",
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact",
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In",
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset.",
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered.",
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username",
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid",
    },
  },
  CLIENTS: {
    INDIVIDUAL: "Independent clients",
    GROUPS: "Groups",
    GROUP: "Group",
    SEARCH: "Search",
    GROUP_NAME: "Group name",
    CLIENTS: "Clients",
    ADD_GROUP: "Add a new group",
    EDIT_GROUP: "Edit group",
    ADD: "Add",
    SAVE: "Save",
    ERROR_NAME: "Group name cannot be empty",
    ERROR_LIST: "Client list cannot be empty",
    ERROR_OTHER: "Cannot add group",
    ERROR_EXISTS: "Cannot add group. Some of the companies might exist in other groups.",
    WARNING_NAME:
      "Group name already exists. Continuing the operation will override the previous group.",
  },
  ONBOARD: {
    NEW_CLIENT: "New customer information",
    NEW_CLIENT_DETAILS: "Customer details",
    SIMILAR_CLIENTS: "Similar clients",
    PREDICTIONS: "Prediction for new customer",
    CUI: "CUI",
    COMPANY_NAME: "Name",
    SELECT_ORGANIZATION: "Input customer CUI",
    SEARCH: "Get information",
    SEARCH_RESULTS: "We found the following information for the above customer",
    ERROR_SEARCH:
      "We could not find any customer matching your criteria. Please input the following data to define a new organization.",
    REVIEW_CLIENT_INFO: "Please review the below information and then click ",
    INPUT_CLIENT_INFO:
      "Please input the following information for the new customer. When done, click ",
    PROCESS: "Continue",
    ENERGY_TYPE: "Energy type",
    CONTRACT_START_DATE: "Contract start date",
    COMPANY_ADDRESS: "Address",
    COMPANY_ADDRESS_CITY: "City",
    COMPANY_ADDRESS_COUNTY: "County",
    COMPANY_ADDRESS_REGION: "Region",
    AVG_CONSUME: "Average consume per year",
    PREDICTION_GRAPH_DESC: "Prediction for 12 months",
    REAL_GRAPH_DATA_DESC: "Real consume for the similar clients",
    REMOVE: "Remove",
    ADD_NEW_CLIENT: "Add",
    LAST_YEAR: "Insert passed consume for last year *",
    TWO_YEARS_AGO: "Insert passed consume for two years ago *",
    SAVE: "Save",
    SELECT_ENERGY_TYPE: "Please select the energy type from the step above.",
    NOTE: "* Please specify at least one data point for either years",
    UPLOAD_NOTE: "Uploaded data type: ",
    MANUAL_UPLOAD: "Manual",
    FILE_UPLOAD: "File upload",
    DOWNLOAD: "Download",
    EXAMPLE: "sample file",
  },
  ENERGY_MANAGEMENT: {
    CONFIG: "Configuration",
    PROCESS: "View",
    HISTORICAL_DATA: "Historical data",
    PREDICTED_VALUE: "Predicted value",
    CORRECTION: "Add correction",
    CORRECTION_VALUE: "Prediction value",
    CORRECTION_REASON: "Reason",
    CORRECTION_EVENT: "Event",
    CORRECTION_START_DATE: "Start date",
    CORRECTION_END_DATE: "End date",
    TOTAL_FOR_VALUE: "Total value for ",
    DAY: "This day",
    DAY_LAST: "Last day",
    DAY_YEAR: "Same day last year",
    MONTH: "This month",
    MONTH_LAST: "Last month",
    MONTH_YEAR: "Same month last year",
    SOLAR_RADIATION: "Solar radiation",
    REAL: "Real data",
    PREDICTED: "Predicted data",
    LAST_YEAR: "Last year",
    THIS_YEAR: "This year",
    EXPORT: "Export",
    EXPORT_MONTH_ALL: "Export month",
    TOTAL_PREDICTED: "Predicted",
    TOTAL_REAL: "Real",
    HEADER_TOTAL: "Total",
    HEADER_VALUE: "Value (MW)",
  },
  EVENT_MANAGEMENT: {
    TABLE_HEADER: {
      CLIENT_NAME: "Customer Name",
      CLIENT_CUI: "Customer CUI",
      ENERGY_TYPE: "Energy type",
      EVENT_TYPE: "Event type",
      REASON: "Reason",
      VALUE: "Energy value",
      START_DATE: "Start date",
      START_HOUR: "Start hour",
      END_DATE: "End date",
      END_HOUR: "End hour",
      ACTIONS: "Actions",
      HOLIDAY: "Holiday",
    },
    SEARCH: "Search",
    FILTER_EVENTS: "Filter events",
    EVENTS: "Event list",
    EVENT_NAME: "Event name",
    EVENT_PREDICTION: "Prediction value",
    EVENT_REASON: "Reason",
    EVENT_TYPE: "Event type",
    EVENT_START_DATE: "Start date",
    EVENT_END_DATE: "End date",
    LOADING: "Loading... Please wait",
    ADD_NEW: "Add new event",
    EDIT: "Edit event",
    SAVE: "Save",
    CONFIRM_DELETE: "Are you sure you want to delete this event?",
    CANCEL: "Cancel",
    OK: "OK",
    EVENT_TYPES: {
      POWER_OUTAGE: "Power outage",
      WEATHER: "Bad weather",
      WIND: "Wind",
      OTHER: "Other",
    },
    PUBLIC_HOLIDAYS: "Public holidays",
    DATE: "Add public holidays separated by comma. You must comply to the yyyy-mm-dd format. For example: 2022-06-01, 2022-12-31",
  },
  DASHBOARD: {
    HELLO: "Select dashboard type",
    TODAY: "Today",
    YESTERDAY: "Yesterday",
    TOMORROW: "Tomorrow",
    GLOBAL_CONSUME: "Total consumption",
    GLOBAL_PRODUCE: "Total production",
    CONSUME: "Consumption",
    PRODUCE: "Production",
    PERCENTAGE: "Change",
    NO_EVENTS: "No events",
    REAL: "Real",
    PREDICTED: "Predicted",
    EVENTS: "Events",
    TOP_3: "Top 3 consumers",
    TOP_3_PRODUCTION: "Top 3 producers",
    EVENT_TYPE: "Event type",
    EVENT_NO: "# companies affected",
    ALL: "All",
  },
  FILTER: {
    OR: "or",
    NO_DATA: "No data available",
    ALL: "All",
    ENERGY_TYPE_LABEL: "Energy type",
    PERIOD_LABEL: "Granularity",
    REGION_LABEL: "Region",
    COUNTY_LABEL: "County",
    COMPANY_CUI_LABEL: "Company CUI",
    COMPANY_NAME_LABEL: "Company name",
    GROUP_LABEL: "Group name",
    RENEWABLE_LOCATION_LABEL: "Location",
    PERIOD: {
      YEAR: "Year",
      MONTH: "Month",
      DAY: "Day",
      HOUR: "Hour",
    },
    REGION: {
      NORTH_EAST: "North-East",
      SOUTH_EAST: "South-East",
      SOUTH_MUNTENIA: "South Muntenia",
      SOUTH_WEST_OLTENIA: "South-West Oltenia",
      WEST: "West",
      NORTH_WEST: "North-West",
      CENTER: "Center",
      BUCHAREST: "București - Ilfov",
    },
    ENERGY: {
      ENERGY: "Electric",
      GAS: "Gas",
      RENEWABLE: "Renewable Energy",
    },
  },
  IMPORT: {
    IMPORT: "Import data",
    FILE_INPUT: "File input",
    UPLOAD: "Upload",
    CLIENTS: "clients",
    NEW_CLIENTS: "new clients",
    CLIENTS_IMPORTED: "Imported data for",
    FOUND: "Found",
    CLIENTS_DETAILS: "New clients information:",
    DATE: "Date",
    UPLOAD_PLACEHOLDER: "Choose a file or drop it here...",
    UPLOAD_DROP: "Drop file here...",
    FILE: "File name",
    STATUS: "Status",
    USER: "User",
    UPLOAD_TIME: "Upload time",
    UNDO: "Undo last import",
    GRANULARITY: "Granularity",
    DAY: "Day",
    MONTH: "Month",
    PENDING: "Pending",
    PROGRESS: "In progress",
    COMPLETED: "Completed",
    ERROR: "Error",
    ADDED_COMPANIES: "Added companies",
    MODIFIED_COMPANIES: "Modified companies",
  },
  PERFORMANCE: {
    COMPANY_DATA: "Company data",
    THRESHOLD_TOP: "High",
    THRESHOLD_BOTTOM: "Low",
    DATA: "Measured",
    PREDICTION: "Prediction",
    TITLE: "Performance",
    STATS: "Statistics",
  },
  ERROR: {
    DEFAULT: "Error processing request",
  },
  administration: {
    register_new_user: {
      button_title: "Register new user",
      modal_title: "Register new user",
      username_field: "Username",
      username_placeholder: "Username",
      firstname_field: "First name",
      firstname_placeholder: "First name",
      lastname_field: "Last name",
      lastname_placeholder: "Last name",
      email_field: "Email",
      email_placeholder: "Email",
      username_validation_message_1: "Enter at least 4 characters.",
      username_validation_message_2: "Please enter the username.",
      firstname_validation_message_1: "Enter at least 4 characters.",
      firstname_validation_message_2: "Please enter the first name.",
      lastname_validation_message_1: "Enter at least 4 characters.",
      lastname_validation_message_2: "Please enter the last name.",
      email_validation_message_1: "Enter a valid email address.",
      email_validation_message_2: "Please enter the email address.",
      success_message: "The user was registered successfully.",
      error_message:
        "The user couldn't be registered, we apologize for the inconvenience.",
    },
    all_users: {
      no_users_registered: "No users registered yet.",
      all_users_error_message:
        "Error occurred while trying to retrieve all the users, we apologize for the inconvenience.",
      user_rights: "User rights overview",
      no_rights_assigned: "This user doesn't have any rights assigned.",
      user_rights_error_message:
        "Error occurred while trying to retrieve user's rights, we apologize for the inconvenience.",
      username_header: "USERNAME",
      firstname_header: "FIRST NAME",
      lastname_header: "LAST NAME",
      email_header: "EMAIL",
      actions_header: "ACTIONS",
      view_assigned_rights_action_tooltip: "View assigned rights",
      edit_rights_action_tooltip: "Edit rights",
      remove_rights_action_tooltip: "Remove user",
    },
    edit_user_rights: {
      modal_title: "Edit user rights",
      no_rights_assigned: "This user doesn't have any rights assigned.",
      error_message:
        "Error occurred while trying to retrieve user's rights, we apologize for the inconvenience.",
      rights_label: "Rights:",
      save_label: "Save",
      close_label: "Close",
      success_message: "User's rights were edited successfully.",
      error_message_1:
        "User's rights couldn't be edited, we apologize for the inconvenience",
    },
    remove_user: {
      confirmation_message:
        "Are you sure you want to remove this user ( {0} )?",
      success_message: "The user was removed successfully.",
      error_message:
        "Error occurred while trying to remove the user, we apologize for the inconvenience",
      modal_title: "Remove user",
    },
  },
};
