import Vue from "vue";
import axios from "axios";
import router from "../../router";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

// const BASEURL = "http://127.0.0.1:8000";
const BASEURL = "https://energy.readerbench.com";
/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
  },

  setCSRFToken() {
    let csrftoken = document.querySelector("[name=csrfmiddlewaretoken]");
    if (csrftoken) {
      csrftoken = csrftoken.value;
    } else {
      csrftoken = this.getCookie("csrftoken");
    }
    if (csrftoken) {
      Vue.axios.defaults.headers.common["X-CSRF-TOKEN"] = csrftoken;
    }
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    this.setCSRFToken();
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Token ${JwtService.getToken()}`;
  },

  /**
   * Set the default HTTP request headers
   */
  setMultipartHeader() {
    Vue.axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
  },

  query(resource, params) {
    return Vue.axios.get(BASEURL + resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  isUnauthorizedThenLogout(error) {
    if (error.response.status == 401 || error.response.status == 403) {
      // Unauthorized || Forbidden
      this.logout();
    } else {
      throw new Error(`[KT] ApiService ${error}`);
    }
  },

  logout() {
    router.push({ name: "login" });
    JwtService.destroyToken();
    const headers = { Authorization: `Token ${JwtService.getToken()}` };
    Vue.axios.post(BASEURL + "/auth/logout/", { headers: headers });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    this.setCSRFToken();
    const headers = { Authorization: `Token ${JwtService.getToken()}` };
    return Vue.axios
      .get(`${BASEURL + resource}/${slug}`, { headers: headers })
      .catch((error) => {
        this.isUnauthorizedThenLogout(error);
      });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    if (resource.includes("login")) {
      Vue.axios.defaults.headers.common["Authorization"] = "";
      this.setCSRFToken();
    } else {
      this.setHeader();
    }
    return Vue.axios.post(`${BASEURL + resource}`, params).catch((error) => {
      this.isUnauthorizedThenLogout(error);
    });
  },

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  },

  postFormData(resource, params) {
    this.setHeader();
    this.setMultipartHeader();
    return Vue.axios.post(`${BASEURL + resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${BASEURL + resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${BASEURL + resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    this.setHeader();
    return Vue.axios.delete(BASEURL + resource).catch((error) => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  download(resource, params) {
    if (resource.includes("login")) {
      Vue.axios.defaults.headers.common["Authorization"] = "";
      this.setCSRFToken();
    } else {
      this.setHeader();
    }
    return Vue.axios.post(`${BASEURL + resource}`, params, {
      responseType: "blob",
    });
  },
};

export default ApiService;
