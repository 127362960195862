import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/dashboard/GlobalDashboard.vue"),
        },
        {
          path: "/dashboard/energy/consumption",
          name: "dashboard-ec",
          component: () =>
            import("@/view/pages/dashboard/DashboardEnergyConsumption.vue"),
        },
        {
          path: "/dashboard/energy/production",
          name: "dashboard-ep",
          component: () =>
            import("@/view/pages/dashboard/DashboardEnergyProduction.vue"),
        },
        {
          path: "/dashboard/gas",
          name: "dashboard-g",
          component: () => import("@/view/pages/dashboard/DashboardGas.vue"),
        },
        {
          path: "/clients",
          name: "clients",
          component: () => import("@/view/pages/Clients.vue"),
        },
        {
          path: "/onboard",
          name: "onboard",
          component: () => import("@/view/pages/Onboard.vue"),
        },
        {
          path: "/consumption/energy",
          name: "consumption-e",
          component: () =>
            import("@/view/pages/management/EnergyConsumption.vue"),
        },
        {
          path: "/consumption/gas",
          name: "consumption-g",
          component: () => import("@/view/pages/management/GasConsumption.vue"),
        },
        {
          path: "/generation",
          name: "generation",
          component: () =>
            import("@/view/pages/management/EnergyGeneration.vue"),
        },
        {
          path: "/manage-events",
          name: "manage-events",
          component: () => import("@/view/pages/ManageEvents.vue"),
        },
        {
          path: "/import-energy",
          name: "import-energy",
          component: () => import("@/view/pages/import/ImportEnergy.vue"),
        },
        {
          path: "/import-gas",
          name: "import-gas",
          component: () => import("@/view/pages/import/ImportGas.vue"),
        },
        {
          path: "/performance-electric",
          name: "performance-electric",
          component: () =>
            import("@/view/pages/performance/PerformanceEnergy.vue"),
        },
        {
          path: "/performance-solar",
          name: "performance-solar",
          component: () =>
            import("@/view/pages/performance/PerformanceSolar.vue"),
        },
        {
          path: "/performance-gas",
          name: "performance-gas",
          component: () =>
            import("@/view/pages/performance/PerformanceGas.vue"),
        },
        {
          path: "/administration",
          name: "administration",
          component: () => import("@/view/pages/Administration.vue"),
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/profile/Profile.vue"),
        },
      ],
    },

    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue"),
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue"),
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue"),
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue"),
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue"),
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Login"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login"),
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Register.vue"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});
